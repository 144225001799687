import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SessionService } from './core/services/session.service';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent {
	title: string = 'trovata-angular-app-lite';

	constructor(private sessionService: SessionService) {
		this.sessionService.subscribeToSessionId();
	}
}
